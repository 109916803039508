<template>
  <div>
    <div class="d-flex justify-space-between">
      <v-autocomplete
        v-model="selected"
        v-bind="$attrs"
        :items="zones"
        item-text="name"
        item-value="id"
        label="Zonas"
        :loading="loading"
      />
      <v-btn
        color="primary"
        small
        @click="openCreateZone"
        rounded
        icon
        outlined
        class="ml-2"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <ZoneCreate
      ref="zoneCreate"
      @onAddZone="onAddZone"
    />
  </div>
</template>

<script>
import ZoneServices from '@/store/services/general/ZoneServices';
import { mapGetters } from 'vuex';
import ZoneCreate from '@/views/general/zone/ZoneCreate';
export default {
  components: {
    ZoneCreate,
  },
  data() {
    return {
      loading: false,
      selected: '',
      zones: [],
    };
  },
  created() {
    this.setZones();
  },
  methods: {
    onAddZone(data) {
      this.zones.unshift(data.zone);
      this.selected = data.zone.id;
    },
    openCreateZone() {
      this.$refs['zoneCreate'].dialog = true;
    },
    async setZones() {
      try {
        this.loading = true;
        const response = await ZoneServices.index(this.user.archon.id);
        this.loading = false;
        this.zones = response.data.data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    selected(value) {
      this.$emit('input', value);
    },
  },
}
</script>

<style scoped>

</style>