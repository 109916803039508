<template>
  <div>
    <Loader :loading="loading" />
    <v-row>
      <v-col cols="12" md="9" sm="12">
        <v-form ref="form" onsubmit="return false;">
          <v-row>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <div class="d-flex justify-space-between">
                <SelectEntities
                  ref="selectEntities"
                  v-model="entityDestiny"
                  label="Cliente"
                  outlined
                  :rules="[isRequiredObj]"
                  include="contacts,addresses"
                  type-entity="CUSTOMER"
                  with-details
                />
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="createCustomer"
                  outlined
                  class="ml-2"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <v-text-field
                v-model="order.order_num"
                label="Order #"
                dense
                :error-messages="errorNumber"
                :rules="[isRequired]"
              />
              <!-- <v-textarea
                v-model="order.instructions"
                label="Instrucciones"
                dense
                outlined
                rows="2"
              /> -->
            </v-col>
          </v-row>
        </v-form>
        <Items
          :order-items="items"
          @onRemoveItem="onRemoveItem"
          type-order="SALE"
          :height="heightItems"
        />
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <Inventories
          ref="inventories"
          :warehouse="user.warehouse"
          @onSendSku="onValidatePriceables"
          @onChangePrice="onChangePrice"
          :height="heightInventories"
        />
        <v-card flat>
          <v-card-text>
            <v-text-field
              label="Total Bs."
              dense
              class="text-field-center"
              :value="total"
              disabled
              color="grey lighten-1"
              prepend-icon="mdi-currency-usd"
            />
            <v-text-field
              id="amount_client"
              v-model="amountClient"
              label="Monto Bs."
              dense
              class="text-field-center"
              type="number"
              step="0.01"
              prepend-icon="mdi-cash"
              @click="textSelect('amount_client')"
            />
            <v-text-field
              label="Devolución Bs."
              dense
              class="text-field-center"
              :value="calculateTotal"
              readonly
              color="grey lighten-1"
              prepend-icon="mdi-calculator"
            />
            <div class="d-flex justify-end">
              <v-menu
                origin="center top"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    small
                    tile
                    block
                    :disabled="!validateItems || calculateTotal < 0"
                  >
                    Realizar Venta
                  </v-btn>
                </template>

                <v-list dense light>
                  <v-list-item link @click="preStoreOrder('SALE')">
                    <v-list-item-title>
                      <v-icon left>mdi-currency-usd</v-icon>
                      Al Contado
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="preStoreOrder('SALE_PAYMENT')">
                    <v-list-item-title>
                      <v-icon left>mdi-format-list-group</v-icon>
                      Crédito
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <PriceableManager
      ref="priceableManager"
      type-price="SELL"
      @onSetPrice="onSetPrice"
    />
    <MyDialog
      ref="myDialog"
      @onYes="storeOrder"
    />
    <CustomerCreate
      ref="customerCreate"
      @onSendEntity="onGetEntity"
    />
  </div>
</template>

<script>
//services
import OrderServices from '@/store/services/warehouse/OrderServices';
import StatusServices from '@/store/services/general/StatusServices';
// components
import Inventories from '@/views/warehouse/order/OrderInventories';
import Items from './OrderItems';
import SelectEntities from '@/components/commons/SelectEntities';
import PriceableManager from '@/views/general/priceable/PriceableManager';
import CustomerCreate from '@/components/commons/customer/CustomerCreate';
// helpers
import { mapGetters } from 'vuex';
import { objectEmpty, textSelect } from '@/constants/helpers';
export default {
  components: {
    Inventories,
    Items,
    SelectEntities,
    PriceableManager,
    CustomerCreate,
  },
  data() {
    return {
      errorNumber: '',
      loading: false,

      items: [],
      warehouses: [],
      statuses: [],

      order: {
        user_id: '',
        origin_warehouse_id: null,
        destiny_warehouse_id: null,
        buy_contact_id: null,
        bill_contact_id: null,
        order_num: this.getOrderNum(),
        instructions: '',
        total: 0,
        delivery_date: new Date(),
        type: '',
        status_id: '',
      },

      warehouseDestiny: {},
      entityDestiny: {},

      amountClient: 0,

      //rules
      isRequired: value => !!value || 'Campo Requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',

      heightInventories: window.innerHeight - 350,
      heightItems: window.innerHeight - 190,
    };
  },
  created() {
    this.getStatuses();
  },
  methods: {
    createCustomer() {
      this.$refs['customerCreate'].dialog = true;
    },
    onGetEntity(data) {
      console.log(data);
      this.$refs['selectEntities'].entities.unshift(data.entity);
      this.$refs['selectEntities'].selected = Object.assign({}, data.entity);
    },
    textSelect(id) {
      textSelect(id);
    },
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    getOrderNum() {
      const n = Math.round(Math.random()*999999 + 9999999);
      return `O-${n}`;
    },
    onSetPrice(data) {
      this.onGetSku(data.data, data.priceable);
    },
    onChangePrice(data) {
      this.$refs['priceableManager'].sku = data.sku;
      this.$refs['priceableManager'].priceables = data.sku.priceables;
      this.$refs['priceableManager'].dialog = true;
    },
    onValidatePriceables(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.id);
      if (index !== -1) {
        this.$notify({
          group: 'petition',
          type: 'info',
          title: data.sku.sku,
          text: `Este SKU ya fue selecionado`,
        });
        return ;
      }
      const priceables = data.sku.priceables.filter(priceable => priceable.price.type === 'SELL');
      if (priceables.length >= 2) {
        // this.currentData = data;
        this.$refs['priceableManager'].sku = Object.assign({}, data);
        this.$refs['priceableManager'].priceables = [...priceables];
        this.$refs['priceableManager'].dialog = true;
      } else if (priceables.length === 1) {
        const priceable = Object.assign({}, priceables[0]);
        this.onGetSku(data, priceable);
      } else {
        this.$notify({
          group: 'petition',
          type: 'info',
          title: data.sku.sku,
          text: `Este SKU no tiene precios, debe agregar al menos un precion de venta`,
        });
        return ;
      }
    },
    onGetSku(data, priceable) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.id);
      if (index === -1) {
        const newItem = {
          sku_id: data.sku.id,
          sku: data.sku,
          qty_available: data.qty_available,
          discount: 0,
          quantity: 1,

          sold_price: priceable?.value ?? 0,
          min_sold_price: priceable?.value ?? 0,
          package_quantity: priceable?.units ?? 1,
          package_name: priceable?.package?.name ?? 'Pieza',
        };
        this.items.unshift(newItem);
      } else {
        
        // const oldQuantity = parseInt(this.items[index].quantity);
        // this.items[index].quantity = oldQuantity + 1;
      }
    },
    onRemoveItem(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.sku_id);
      this.items.splice(index, 1);
    },
    preStoreOrder(type) {
      let status = {};
      if (type === 'SALE_PAYMENT') {
        status = this.getStatus('UNDERPAYMENT');
      } else {
        status = this.getStatus('SALE');
      }
      this.order.type = type;
      this.order.status_id = status.id;
      const myDialog = this.$refs['myDialog'];
      myDialog.configuration = {
        iconTitle: 'mdi-package-variant-closed-check',
        title: 'Realizar Venta',
        message: '¿Esta seguro de proceder a realizar la venta?',
        typeDialog: 'warning',
      };
      myDialog.dialog = true;
    },
    async storeOrder() {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) {
          try {
            const contacts = this.entityDestiny?.contacts ?? [];
            this.errorNumber = '';
            this.order.user_id = this.user.id;
            this.order.origin_warehouse_id = this.user.warehouse.id;
            this.order.buy_contact_id = contacts.length > 0 ? contacts[0].id : -1;
            this.order.bill_contact_id = contacts.length > 0 ? contacts[0].id : -1;
            this.order.total = this.total;
            this.order['items'] = this.items.map(item => ({
              sku_id: item.sku_id,
              quantity: item.quantity,
              sold_price: item.sold_price,
              package_quantity: item.package_quantity,
              package_name: item.package_name,
            }));
            this.loading = true;
            await OrderServices.store(this.user.archon.id, this.order);
            this.$router.push({
              name: 'saleList',
            });
            this.$notify({
              group: 'petition',
              title: 'Orden',
              type: 'success',
              text: `Orden ${this.order.order_num} registrado exitosamente`,
            });
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.$notify({
              group: 'petition',
              type: 'error',
              title: 'Orden',
              text: `Orden ${this.order.order_num} no se pudo guardar`,
            });
            this.loading = false;
            if (error.response.status === 422) {
              const errorNumber = error.response.data.errors?.order_num[0] || null;
              if (errorNumber) {
                this.errorNumber = errorNumber;
              }
            }
          }
        } else this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Skus',
          text: `La orden no tiene skus agregados`,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    validateItems() {
      return this.items.filter(sku => 
        (parseInt(sku.quantity * sku.package_quantity) > parseInt(sku.qty_available)) ||
        (parseFloat(sku.sold_price) < (sku.min_sold_price))
      ).length === 0;
    },
    total() {
      let total = 0;
      this.items.forEach(item => {
        total += parseFloat(item.sold_price * item.quantity) - parseFloat(item.discount);
      });
      return total.toFixed(2);
    },
    calculateTotal() {
      return (this.amountClient - this.total).toFixed(2);
    },
  },
}
</script>

<style scoped>
.text-field-center >>> input {
  text-align: center;
}
</style>