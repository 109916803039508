import ApiService from '../Api';

class SkuServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, warehouse, params = {}) {
    return this.api.get(`${this.resource}/${archon}/inventories/${warehouse}`, { params });
  }

  storeBulk(archon, warehouse, body) {
    return this.api.post(`${this.resource}/${archon}/inventories/${warehouse}/store-bulk`, body);
  }

  reports(archon, warehouse, body) {
    return this.api.get(`${this.resource}/${archon}/inventories/${warehouse}/inventory-report`, body);
  }
}

export default new SkuServices();