<template>
  <v-card flat min-height="100">
    <LoaderDotSpinner absolute :loading="loading" />
    <div class="d-flex justify-space-between px-2">
      <div style="width: 150px;">
        <v-text-field
          id="textSearch"
          v-model="search"
          dense
          label="Buscar"
          type="text"
          @click="textSelect('textSearch')"
        />
      </div>
      <v-btn
        @click="getInventories"
        color="warning"
        small
        text
      >
        <v-icon left>mdi-refresh</v-icon>
        Refrescar
      </v-btn>
    </div>

    <TScroll :height="height">
      <v-list two-line>
        <v-divider />
        <template v-for="(item, index) in skusFiltered">
          <v-list-item
            :key="`inv-${index}-${item.id}`"
            link
          >
            <v-list-item-content @click="addSkuToOrder(item, index)">
              <v-list-item-title>
                <div><b>{{ item.sku.sku }}</b></div>
                <div style="font-size: 10px;"> {{ item.sku.product.name }} - <b>{{ item.sku.description }}</b> </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div style="font-size: 10px;">
                  En almacén: <b> {{ item.qty_available }} </b>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="info"
                small
                icon
                @click="showInfo"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`div-${index}-${item.id}`" />
        </template>
      </v-list>
    </TScroll>
  </v-card>
</template>

<script>
import TScroll from '@/components/commons/tiny/TScrollSimple';
import InventoryServices from '@/store/services/inventory/InventoryServices';
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
import { objectEmpty, textSelect } from '@/constants/helpers';
export default {
  mixins: [ListMixin],
  components: {
    TScroll,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    warehouse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      inventories: [],

      search: '',
    };
  },
  mounted() {
    this.getInventories();
  },
  methods: {
    textSelect(id) {
      textSelect(id);
    },
    async getInventories() {
      if (!objectEmpty(this.warehouse)) {
        this.search = '';
        try {
          const params = {
            order_by: 'created_at',
            order_direction: 'DESC',
            include: 'sku.product,sku.priceables.price,sku.priceables.package',
          };
          this.loading = true;
          const response = await InventoryServices.index(this.user.archon.id, this.warehouse.id, params);
          this.inventories = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
          this.total = response.data.total;
          
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getInventories();
      else this.currentPage = 1;
    },
    addSkuToOrder(item, index) {
      this.$emit('onSendSku', {
        index,
        qty_available: item.qty_available,
        quantity: 1,
        sku: item.sku,
      });
    },
    valueInclude(value) {
      if (value) {
        return value.toUpperCase().includes(this.search.toUpperCase());
      } else return false;
    },
    showInfo() {},
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    skusFiltered() {
      if (this.search !== '') {
        return this.inventories.filter(item => (
          this.valueInclude(item.sku.sku) ||
          this.valueInclude(item.sku.description) ||
          this.valueInclude(item.sku.product.name) ||
          this.valueInclude(item.sku.product.description)
        ));
      } else return this.inventories;
    },
  },
  watch: {
    warehouse() {
      this.getInventories();
    },
  },
}
</script>