<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="50%"
  >
    <v-card>
      <LoaderDotSpinner absolute :loading="loading"/>
      <v-card-title>
        <v-icon left>mdi-account-settings</v-icon>
        {{ contactId === -1 ? 'Nuevo Cliente' : 'Editar Cliente' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                v-model="nit"
                label="NIT"
                dense
                :rules="[isRequired, isNit]"
                :error-messages="messageNit"
              />
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                v-model="name"
                label="Razon Social"
                dense
                :rules="[isRequired]"
              />
            </v-col>
          </v-row>
          <div>
            <v-btn
              x-small
              text
              color="info"
              @click="showMoreInfo = !showMoreInfo"
            >
              <v-icon small> {{ showMoreInfo ? `mdi-minus` : `mdi-plus` }} </v-icon>
              Información
            </v-btn>
          </div>
          <v-expand-transition>
            <div v-show="showMoreInfo" class="mt-2">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="contact.email1"
                    label="Email"
                    dense
                    :rules="showMoreInfo ? [isEmail] : []"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="contact.phone1"
                    label="Teléfono"
                    dense
                    :rules="showMoreInfo ? [isRequired] : []"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address.address1"
                    label="Dirección"
                    dense
                    :rules="showMoreInfo ? [isRequired] : []"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address.location_url"
                    label="Url Ubicación"
                    dense
                  />
                </v-col>
                <!-- <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address.country"
                    label="País"
                    dense
                    :rules="showMoreInfo ? [isRequired] : []"
                  />
                </v-col> -->
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address.state"
                    label="Departamento"
                    dense
                    :rules="showMoreInfo ? [isRequired] : []"
                  />
                </v-col>
                <!-- <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address.zip_code"
                    label="Código Postal"
                    dense
                  />
                </v-col> -->
                <v-col cols="12" md="6" sm="12">
                  <AutocompleteZone
                    ref="autocompleteZone"
                    v-model="address.zone_id"
                    :rules="showMoreInfo ? [isRequired] : []"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    v-model="contact.notes"
                    label="Notas"
                    dense
                    rows="3"
                  />
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="contactId === -1"
          small
          color="success"
          @click="storeCustomer"
        >
          Guardar
        </v-btn>
        <v-btn
          v-else
          small
          color="warning"
          @click="editCustomer"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactServices from '@/store/services/general/ContactServices';
import EntityServices from '@/store/services/general/EntityServices';
import AddressServices from '@/store/services/general/AddressServices';
import AutocompleteZone from '@/components/commons/zone/AutocompleteZone';
import { isEmail, isNit } from '@/constants/rules';
import { mapGetters } from 'vuex';
export default {
  components: {
    AutocompleteZone,
  },
  props: {},
  data() {
    return {
      dialog: false,

      loading: false,

      name: '',
      nit: '',
      messageNit: '',
      contact: {
        contactable_type: 'entity',
        contactable_id: '',
        user_id: -1,
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
        notes: '',
      },
      address: {
        addressable_type: 'entity',
        addressable_id: '',
        address1: '',
        address2: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '',
        latitude: '',
        longitude: '',
        location_url: '',
        zone_id: '',
      },

      showMoreInfo: false,

      contactId: -1,
      entityId: -1,
      addressId: -1,

      // rules
      isRequired: value => !!value || 'Campo Requerido',
      isNit: value => isNit(value) || 'Nit Inválido',
      isEmail: value => isEmail(value) || 'Email inválido',
    };
  },
  methods: {
    async storeCustomer() {
      if (this.$refs.form.validate()) {
        try {
          this.messageNit = '';
          const bodyEntity = {
            name: this.name,
            description: null,
            nit: this.nit,
            type: 'CUSTOMER',
          };
          this.contact.user_id = this.user.id;
          this.contact.name = this.name;
          this.contact.ic = this.nit;
          this.loading = true;
          const responseEntity = await EntityServices.store(this.user.archon.id, bodyEntity);
          this.contact.contactable_id = responseEntity.data.id;
          const rpseContact = await ContactServices.store2(this.user.archon.id, this.contact);
          this.contact.id = rpseContact.data.id;
          if (this.showMoreInfo) {
            this.address.addressable_id = responseEntity.data.id;
            this.address['include'] = 'zone';
            const responseAddress = await AddressServices.store(this.user.archon.id, this.address);
            this.address['zone'] = responseAddress.data.zone;
          }
          const entity = responseEntity.data;
          entity['contacts'] = [this.contact];
          entity['addresses'] = [this.address];
          this.$emit('onSendEntity', {
            entity,
            type: 'store',
          });
          this.loading = false;
          this.$notify({
            group: 'petition',
            title: 'Cliente',
            type: 'success',
            text: `${this.name} fue agregado`,
          });
          this.closeDialog();
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            this.messageNit = error.response.data.errors?.nit[0] ?? '';
          }
        }
      }
    },
    async editCustomer() {
      if (this.$refs.form.validate()) {
        try {
          this.messageNit = '';
          const bodyEntity = {
            name: this.name,
            description: null,
            nit: this.nit,
            type: 'CUSTOMER',
          };
          this.contact.name = this.name;
          this.contact.ic = this.nit;
          this.loading = true;
          const responseEntity = await EntityServices.update(this.user.archon.id, this.entityId, bodyEntity);
          await ContactServices.update(this.user.archon.id, this.contactId, this.contact);
          if (this.showMoreInfo) {
            this.address['include'] = 'zone';
            if (this.addressId === -1)  {
              this.address.addressable_id = responseEntity.data.id;
              const responseAddress = await AddressServices.store(this.user.archon.id, this.address);
              this.address['zone'] = responseAddress.data.zone;
            } else {
              const responseAddress = await AddressServices.update(this.user.archon.id, this.addressId, this.address);
              this.address['zone'] = responseAddress.data.zone;
            }
          }
          const entity = responseEntity.data;
          entity['contacts'] = [this.contact];
          entity['addresses'] = [this.address];
          this.$emit('onSendEntity', {
            entity,
            type: 'update',
          });
          this.loading = false;
          this.$notify({
            group: 'petition',
            title: 'Cliente',
            type: 'success',
            text: `${this.name} fue agregado`,
          });
          this.closeDialog();
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            this.messageNit = error.response.data.errors?.nit[0] ?? '';
          }
        }
      }
    },
    closeDialog() {
      this.name = '';
      this.nit = '';

      this.contact = {
        contactable_type: 'entity',
        contactable_id: -1,
        user_id: -1,
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
        notes: '',
      };
      this.address = {
        addressable_type: 'entity',
        addressable_id: -1,
        address1: '',
        address2: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '',
        latitude: '',
        longitude: '',
        location_url: '',
        zone_id: '',
      };
      this.$refs['form'].resetValidation();
      this.$refs['autocompleteZone'].selected = '';

      this.dialog = false;

      this.showMoreInfo = false;

      this.entityId = -1;
      this.contactId = -1;
      this.addressId = -1;
    },
    setZoneId(id) {
      setTimeout(() => {
        this.$refs['autocompleteZone'].selected = id;
      }, 500);
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    'address.state'(value) {
      this.address.city = value;
    },
  },
};
</script>