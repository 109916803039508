<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orderItems"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
      :height="height"
      dense
    >
      <template v-slot:item.sku="{ item }">
        <div>
          <div><b>{{ item.sku.sku }}</b></div>
          <div>
            <small class="text--secondary"> {{ item.sku.product.name }} </small>
          </div>
          <div>
            <small class="text--secondary"><b> {{ item.sku.description }} </b></small>
          </div>
        </div>
      </template>
      <template v-slot:item.qty_available="{ item }">
        <div :class="(item.quantity * item.package_quantity) > item.qty_available ? 'red--text' : 'green--text'">
          <b>{{ item.qty_available }}</b>
        </div>
      </template>
      <template v-slot:item.sub_total="{ item }">
        <div> {{ (item.quantity * item.sold_price) - item.discount }} </div>
      </template>
      <template v-slot:item.quantity="{ item }">
        <v-text-field
          v-model="item.quantity"
          :suffix="item.package_name"
          type="number"
          step="1"
          class="text-field-center"
          :hint="`${item.package_quantity * item.quantity} Unidades.`"
          persistent-hint
        />
      </template>
      <template v-slot:item.sold_price="{ item }">
        <div class="d-flex justify-space-between">
          <v-text-field
            v-model="item.sold_price"
            type="number"
            step="0.01"
            class="text-field-center"
            :hint="`Bs: ${item.min_sold_price}`"
            persistent-hint
          />
        </div>
      </template>
      <template v-slot:item.discount="{ item }">
        <v-text-field
          v-model="item.discount"
          type="number"
          class="text-field-center"
          step="0.01"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="removeItem(item)" small color="red" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 300,
    },
    orderItems: {
      type: Array,
      default: () => [],
    },
    typeOrder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: this.orderItems,
    };
  },
  methods: {
    removeItem(item) {
      this.$emit('onRemoveItem', {
        sku: item,
      });
    },
    onChangePrice(item) {
      this.$emit('onChangePrice', {
        sku: item,
      });
    },
  },
  computed: {
    headers() {
      let headers = [
        {
          text: 'Sku',
          sortable: false,
          value: 'sku',
          width: '20%',
        },
        {
          text: 'Inventario Unid.',
          value: 'qty_available',
          sortable: false,
          align: 'center',
          width: '10%',
        },
        { 
          text: 'Cantidad',
          value: 'quantity',
          sortable: false,
          align: 'center',
          width: '20%',
        },
      ];
      if (this.typeOrder === 'SALE') {
        headers.push({
          text: 'Precio venta',
          value: 'sold_price',
          sortable: false,
          align: 'center',
          width: '15%',
        });
        headers.push({
          text: 'Descuento',
          value: 'discount',
          sortable: false,
          align: 'center',
          width: '15%',
        });
        headers.push({
          text: 'Sub Total',
          value: 'sub_total',
          sortable: false,
          align: 'center',
          width: '15%',
        });
      }
      headers.push({
        text: '',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
      });
      return headers;
    },
  },
};
</script>

<style scoped src="@/styles/InputSimple.css" />

<style scoped>
.sku-without-inventory {
  color: red;
  font-size: 20px;
}
.text-field-center >>> input {
  text-align: center;
}
</style>