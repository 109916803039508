<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-currency-usd</v-icon>
        Seleccione un precio
      </v-card-title>
      <v-list two-line>
        <v-list-item-group
          v-model="selected"
          active-class="success--text"
        >
          <v-divider />
          <template v-for="(item, index) in priceables">
            <v-list-item :key="item.id" link @click="selectPrice(item)">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title :class="`${active ? 'font-weight-bold' : ''}`">
                    {{ item.package.name }} - <small class="text--secondary">Unid: ({{ item.units }})</small>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    Bs {{ item.value }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon :color="active ? 'success' : 'grey lighten-1'">
                    mdi-check
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider :key="`div-price-${index}`"/>
          </template>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          small
          color="grey lighten-1"
        >
          Cancelar
        </v-btn>
        <v-btn
          :disabled="selected === null"
          @click="applyPrice"
          small
          color="primary"
        >
          Aplicar precio
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      currentPrice: {},

      selected: null,

      priceables: [],

      sku: {},
    };
  },
  methods: {
    selectPrice(item) {
      this.currentPrice = item;
    },
    applyPrice() {
      this.$emit('onSetPrice', {
        priceable: Object.assign({}, this.currentPrice),
        data: this.sku,
      })
      this.dialog = false;
      setTimeout(() => {
        this.resetData();
      }, 250);
    },
    resetData() {
      this.selected = null;
      this.currentPrice = {};
      this.sku = {};
      this.priceables = [];
    },
  },
};
</script>